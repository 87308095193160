import * as React from "react"
import Layout from "../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../components/ImageDownload"

const IndexPage = () => {
    const root = "/img/download/partners"

    return (
        <Layout pageTitle="Partners - Standard Graphics">
            <Row>
                <Col>
                    <Link to="/partners">Back</Link>
                </Col>
            </Row>

            <Row className="mt-2">
                <Col md={8}>
                    
                    <p className="para">
                        You can find more content and graphics in the awards section of the brand website. Below you will find specific partner content. 
                    </p>
        
                    
                </Col>
            </Row>
            
            
            <ImageDownload
                root={root}
                name="Awards General Promo"
                filename="awards-partner"
                text="Use this to promote the Women's Business Awards. You can find promos for both the Awards &amp; Virtual Awards along with a general Awards promo. Use the copy below to accompany your social media posts."
                squareHref="https://www.canva.com/design/DAFb2xKu9rc/kKiA_if1z_WcfLtIvNBNbQ/view?utm_content=DAFb2xKu9rc&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFb2wejIKE/Au50-IsTqdT_9wCw54wySQ/view?utm_content=DAFb2wejIKE&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1m5a_xnEs7XOUzg6vqUIL9oS9BZ2jK4ch6Wbb2ua1OzE/edit?usp=sharing"
            />
        </Layout>
    )
}

export default IndexPage
